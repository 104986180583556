import React, { useState } from "react";
import PromptScopeTree from "./PromptScopeTree";
import InstantInsightPromptDetail from "./InstantInsightPromptDetail";

const InstantInsightPrompts = () => {
  const [selectedNode, setSelectedNode] = useState({});

  const selectedItem = (prompt) => {
    setSelectedNode(prompt);
  };

  return (
    <div className="motif-row ii-prompt-management-tab-container">
      <div className="motif-col-xs-4 motif-col-sm-1">
        <PromptScopeTree setSelectedNode={selectedItem} />
      </div>
      <div className="motif-col-sm-3">
        <InstantInsightPromptDetail selectedNode={selectedNode} />
      </div>
    </div>
  );
};
export default InstantInsightPrompts;
