import { useEffect, useState, useContext } from "react";
import { DashboardContext } from "../../context/dashboardContext.js";
import './CreateProject.css';

import './ProjectDetail.css';
import { MotifTextArea, MotifErrorMessage, MotifTooltip, MotifInput, MotifSelect, MotifOption, MotifFormField, MotifTypeahead } from "@ey-xd/motif-react";
import { ApiManager } from '../../services/apimanager.js';
import helpicon from "../../assets/image/help-circle.svg";
import { groupConfig } from "../../config/groupConfig";
import { bool } from "prop-types";
const actionData = require("../../data/model/actiontype.js");
let actionDataList = actionData.ActionData;


export function ProjectDetail({ disablecheck }, actiontype = actionDataList) {
    const [showProjectNameValid, setShowProjectNameValid] = useState(false);
    const projectMasterData = require("../../data/projectMasterData.js");
    let { allProjects } = useContext(DashboardContext);
    const [category, setCategory] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [isSATUser, setSATUser] = useState(false);
    const [paceID, setPaceID] = useState("");
    const [paceIdList, setPaceIdList] = useState([]);
    const [paceDropdownList, setPaceDropdownList] = useState([]);
    const [paceIDListShow, setPaceIDListShow] = useState(false);
    const [engagementId, setEngagementId] = useState("");
    const [projectName, setProjectName] = useState("");
    const [clientName, setClientName] = useState("");
    const [actualClientName, setActualClientName] = useState("");
    const [isInvalidPaceID, setisInvalidPaceID] = useState(false);
    const [actualConfidentialProject, setActualConfidentialProject] = useState("");
    const [area, setArea] = useState("");
    const [areaList, setAreaList] = useState([]);
    const [region, setRegion] = useState("");
    const [regionList, setRegionList] = useState([]);
    const [country, setCountry] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [dealSize, setDealSize] = useState("");
    const [dealSizeList, setDealSizeList] = useState([]);
    const [targetSector, setTargetSector] = useState("");
    const [targetSectorList, setTargetSectorList] = useState([]);
    const [targetSubSector, setTargetSubSector] = useState("");
    const [targetSubSectorList, setTargetSubSectorList] = useState([]);
    const [targetSubSubSector, setTargetSubSubSector] = useState("");
    const [targetSubSubSectorList, setTargetSubSubSectorList] = useState([]);
    const [dataHosting, setDataHosting] = useState("");
    const [dataHostingList, setDataHostingList] = useState([]);
    const [description, setDescription] = useState("");
    const [wrapperName, setwrapperName] = useState("");
    const [confidentialProject, setConfidentialProject] = useState("");
    const [confidentialProjList, setConfidentialProjList] = useState([]);
    const [categoryDisable, setCategoryDisable] = useState(false);
    const [paceIDDisable, setpaceIDDisable] = useState(false);
    const [engagementIdDisable, setEngagementIdDisable] = useState(false);
    const [projectNameDisable, setProjectNameDisable] = useState(false);
    const [focusedElement, setFocusedElement] = useState(null);
    const [invalidengagementId, setInvalidEngagementId] = useState(false);
    const [ErrorMessageEID, setErrorMessageEID] = useState(0);
    const [confidentialProjectDisable, setConfidentialProjectDisable] = useState(false);
    const [areaDisable, setAreaDisable] = useState(false);
    const [regionDisable, setRegionDisable] = useState(false);
    const [countryDisable, setCountryDisable] = useState(false);
    const [dataHostingDisable, setDataHostingDisable] = useState(false);
    const { GetProjectDetailById } = useContext(DashboardContext);
    const constantData = require("../../data/constant.js");
    const apimanager = new ApiManager();
    const userType = "UserSat";
    let projectNameList = [];
    const requestData = require("../../data/model/request.js");
    let createData = requestData.CreateData;
    let sharedData = requestData.SharedData;
    const isCreate = actionDataList.create;
    const isEdit = actionDataList.edit;
    let projectid = actionDataList.projectId;
    const [projectScope, setProjectScope] = useState([]);
    const [projectScopeList, setProjectScopeList] = useState([]);
    let projectScopeSelected = [];
    let EngagementIDFocused = false;
    let { scopeAll } = useContext(DashboardContext);
    const [showSpecialCharMessage, setShowSpecialCharMessage] = useState(false);
    const [showMaxCharMessage, setShowMaxCharMessage] = useState(false);
    const regex = /[`!@#$%^*+=\[\]{};':"\\|,<>?~\/]/;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setTargetSectorList(projectMasterData.SectorConfig);
        setCategoryList(projectMasterData.CategoryConfig);
        setAreaList(projectMasterData.AreaConfig);
        setDealSizeList(projectMasterData.DealConfig);
        setDataHostingList(projectMasterData.DataHostingListConfig);
        validateCreatePage();
        loadProjectName();
        setConfidentialProjList(projectMasterData.ConfidentialConfig)
        getScopeList();
    })

    useEffect(() => {
        userTypeCheck();
        setWrapperName();
        setSATUser(localStorage.getItem("groupName") == groupConfig.groupNameSat);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Disable Engagement id field for Testing project
    useEffect(() => {
        if(category === 2) {
            setEngagementIdDisable(true);
        }
    }, [category]);

    /**
      * Description
      * -----------
      * This method is to get existing project detail filtering the projectid
      */
    const getScopeList = async () => {
        if (scopeAll) {
            setProjectScopeList(scopeAll);
        }
    }


    /**
      * Description
      * -----------
      * This method is to set wrapper for create edit or explore depending on flags.
      */
    const setWrapperName = () => {
        if (isCreate) {
            setwrapperName(projectMasterData.CreateDataFrame);
            populateDropdownsWithDefaultValues();
        }
        if (isEdit) {
            setwrapperName(projectMasterData.EditDataFrame);
            getPrevData();
        }
        onEditDisable();
    }

     /**
      * Description
      * -----------
      * This method is to populate country, region, area and data hosting location based on current enagagement location.
      */
     const populateDropdownsWithDefaultValues =() => {
        const currentEngagementLocation = JSON.parse(localStorage.getItem("EngagementLocation"));
        if(currentEngagementLocation) {
            let currentCountry = projectMasterData.CountryConfig.filter(country => country.id === currentEngagementLocation.id);
            setCountryList(currentCountry);
            setCountry(currentCountry[0]?.id);

            let currentRegions = projectMasterData.RegionConfig
                .filter(region=>projectMasterData.CountryConfig
                .filter(c=> c.content===currentCountry[0]?.content)
                .map(m=>m.regionId).includes(region.id));
            setRegionList(currentRegions);

            let currentArea = projectMasterData.AreaConfig. filter(area => area.id === currentRegions[0].areaId);
            setAreaList(currentArea);
            setArea(currentArea[0]?.id);

            let currentDataHosting = projectMasterData.DataHostingListConfig.filter(dataHost => dataHost.id === currentCountry[0]?.dataHostingId);
            setDataHosting(currentDataHosting[0]?.id);
			localStorage.setItem('currentDataHostingLocation', currentDataHosting[0]?.id);																			  
        }
     }

    /**
      * Description
      * -----------
      * This method is to get existing project detail filtering the projectid
      */
   const getPrevData = async () => {
        let data = await GetProjectDetailById(projectid, localStorage.getItem('currentDataHostingLocation'));
        if (data) {
            setPrevData(data[0]);
        }
    }
    /**
      * Description
      * -----------
      * This method is to call the set method for existing project detail filtering the projectid
      * Parameters:
      * data: this one is the data that obtained from database by filtering projectid
      */
    const setPrevData = (data) => {
        setConfidentialProject(data.confidential);
        validateCreatePage();
        setCategory(Number(data.category));
        if(Number(data.category) === 2){
            setCategoryDisable(false);
        }
        setPaceID(data.paceId === 0 ? "" : data.paceId);
									
        setEngagementId(data.engagementId === 0 ? "" : data.engagementId);
        validateCreatePage();
											   
		 
        setProjectName(data.projectName);
        setClientName(data.clientName);
        setArea(data.area);
        onAreaChange(data.area);
        setRegion(data.region);
        onRegionChange(data.region);
        setCountry(data.country);
        setDealSize(data.dealSize);
        setTargetSector(data.targetSector);
        onTargetSectorChange(data.targetSector)
        setTargetSubSector(data.target_SubSector);
        onSubSectorChange(data.target_SubSector);
        setTargetSubSubSector(data.target_Sub_SubSector);
        setDataHosting(Number(data.dataHostingLocation));
        setDescription(data.description);
        if (data.confidential === 'Y')
            setConfidentialProject(1);
        else
            setConfidentialProject(2);
        if (data.confidential === "") {
            setConfidentialProject("");
        }
        projectScopeSelected = JSON.parse(data.scopeId);
        onProjectScopeChange(projectScopeSelected);
        validateCreatePage();
    }

    /**
      * Description
      * -----------
      * This method is to disable inputbox and dropdowns during edit action
      */
    const onEditDisable = () => {
        if (isEdit) {
            setCategoryDisable(true);
            if(category===2){
                setCategoryDisable(false);
            }
            setpaceIDDisable(true);
            //Kingshuk's Code to make Engagement-ID Editable
            if(isSATUser)
                {
                    setEngagementIdDisable(false);
                }
            else
                {
                    setEngagementIdDisable(false);
                }
            //Code Ended
            setProjectNameDisable(false);
            setRegionDisable(true);
            setConfidentialProjectDisable(true);
        }
        /* Applicable for create and edit project scenario */
        setAreaDisable(true);
        setCountryDisable(true);
        setDataHostingDisable(true);
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects category dropdown
      * Parameters:
      * data: this one is the selected value by user
      */
    const onCategoryChange = (data) => {
        setCategory(data);
        validateCreatePage();
        if (data !== null) {
            let selectedCategory = categoryList.find(
                (category) => category.id === data
            );
            if (selectedCategory) {
                setCategory(data);
            }
        }
        if (data === 2) {
            setpaceIDDisable(true);
            setEngagementIdDisable(true);
            setConfidentialProjectDisable(true);
            setPaceID("");
            setEngagementId("");
            setClientName("");
            setConfidentialProject("");
            setActualConfidentialProject("");
            setShowProjectNameValid(false);
        }
        else {
            setpaceIDDisable(false);
            setEngagementIdDisable(false);
            setConfidentialProjectDisable(false);
            setPaceID("");
            setEngagementId("");
            setClientName("");
            setConfidentialProject("");
            setActualConfidentialProject("");
        }
        validateCreatePage();
    }


	
    const onPaceIdLeave = async (e) => {   
        setPaceIDListShow(false);     
        if (paceID.length > 2) {
            
            let responsePace = await apimanager.GetPaceId(paceID);
            if (responsePace !== undefined) {
                let paceRequest = [];
                if (responsePace.length > 0) {
                    responsePace.forEach((key, index) => {
                        paceRequest.push(key.paceId.toString());
                    });
                }
                let filteredPaceRequest = paceRequest.filter(o => o.startsWith(paceID))
                if(filteredPaceRequest.length > 0)
                {
                    let FindpaceID = filteredPaceRequest.find((s) => s === paceID);
                    
                    if(!FindpaceID){ 
                        setisInvalidPaceID(true); 
                        validateCreatePage();   }  
                    else
                        { setisInvalidPaceID(false); validateCreatePage(); }     
                }
                else
                       { setisInvalidPaceID(true); validateCreatePage(); }             
            }
            else
            {
                { setisInvalidPaceID(true); validateCreatePage(); }      
            }
        }
        else {
		 
            { setisInvalidPaceID(true); validateCreatePage(); }       
        }
    }
    
    
	 
 


    /**
      * Description
      * -----------
      * This method is to call when user writes paceid and depending on that fetch data from outside api
      * Parameters:
      * e: this one is onChange event parameter to get the input text
      */
    const onPaceIdChange = async (e) => {
        if (e.target.value.length <= 8) {
            setPaceID("");
            setClientName("");
            setActualClientName("");
            setActualConfidentialProject("");
            setConfidentialProject("");
            const regex = /^[0-9\b]+$/;
            if (regex.test(e.target.value)) {
                setPaceID(e.target.value);
                setPaceIdList("");
                setPaceIDListShow(false);
                if (e.target.value.length === 0) {
                    setClientName("");
                    setActualClientName("");
                    setPaceID("");
                    setPaceIDListShow(false);
                }
                if (e.target.value.length > 2) {
                    let responsePace = await apimanager.GetPaceId(e.target.value);
                    if (responsePace !== undefined) {
                        let paceRequest = [];
                        if (responsePace.length > 0) {
                            responsePace.forEach((key, index) => {
                                paceRequest.push(key.paceId.toString());
                            });
                        }
                        let filteredPaceRequest = paceRequest.filter(o => o.startsWith(e.target.value))
                        setPaceDropdownList(filteredPaceRequest);
                        setPaceIdList(responsePace);
                        setPaceIDListShow(true);
                    }
                }
                else {
                    setClientName("");
                    setActualClientName("");
                    setPaceIDListShow(false);
                }
            }
            validateCreatePage();
        }
    }


    
    /**
     * Description
     * -----------
     * This method is to call when user selects paceid and depending on that changes the clientname & mask
     * Parameters:
     * e: this one is onselect event parameter to get the selected paceid
     */
    const onPaceIdSelect = (e) => {
        if (actualConfidentialProject !== 'Y') {
            setPaceID(e);
            let paceDetails = paceIdList.find((s) => s.paceId === Number(e));
            if (paceDetails.clientName === null) {
                setClientName(projectMasterData.ClientNameMask);
                setActualClientName(projectMasterData.ClientNameMask);
                setConfidentialProject(1);
                setActualConfidentialProject(projectMasterData.ConfidentialCheck);
                setConfidentialProjectDisable(true);
            }
            else 
            {
                setClientName(paceDetails.clientName);
                setActualClientName(paceDetails.clientName);
                setConfidentialProjectDisable(false);
                setConfidentialProject(2);
                setActualConfidentialProject(projectMasterData.ConfidentialUnCheck);
            }
            if(paceDetails.length === 0)
            {
                 { setisInvalidPaceID(true); validateCreatePage(); }           
            }
            else
            { setisInvalidPaceID(false); validateCreatePage(); }     

        } else {
            setPaceID(e);
            setClientName(projectMasterData.ClientNameMask);
            setActualClientName(projectMasterData.ClientNameMask);

            let paceDetails = paceIdList.find((s) => s.paceId === Number(e));
            if(paceDetails.length === 0)
                {
                    { setisInvalidPaceID(true); validateCreatePage(); }      
                }
                else
                { setisInvalidPaceID(false); validateCreatePage(); }     
        }
        setPaceIDListShow(false);
        if (category === 1) {
            checkDuplicateProjectName(projectName, e);
        }
    };


    
    //Function to Ensure User won't put something like 00001234 as Engagement ID to Save it
    function isvalidEightDigitNumber(inputString)
    {
        if((inputString!== null)&&(inputString!==undefined))
            {
                if(inputString.length===1) return true;
                 const numStr = inputString.toString();
                 if (/^[1-9]\d{7}$/.test(numStr))
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
            }
    }


    //Function to Ensure User won't put 00000000 as Engagement ID to Save it
    function sumOfDigitsisZero(inputString) {
        let sum = 0;
        if(inputString.length === 1) return true;
        for(let char of inputString)
            {
                
                if(!isNaN(char) && char!== '')
                    {
                        sum += parseInt(char);
                    }
            }
            return sum;
    }
    /**
      * Description
      * -----------
      * This method is to call when user writes engagementid
      * Parameters:
      * e: this one is onChange event parameter to get the input text
      */
    const onEngagementIdChange = (e) => {
        if (e.target.value.length <= 8) {
            const regex = /^[0-9\b]+$/;
            //Additional Check to ensure that there should not be any
            //loopholes to break the system by entering something as '00001234' or '00000000'
            let sumOfDigits = sumOfDigitsisZero(e.target.value);
            let validNumber = isvalidEightDigitNumber(e.target.value);

            if(((e.target.value).length<8)||(sumOfDigits===0)||(!validNumber)){
                setInvalidEngagementId(true);
                sharedData.invalidengagementID = true;
            }else {
                setInvalidEngagementId(false);
                sharedData.invalidengagementID = false;
            }
            if (regex.test(e.target.value)) {
                setEngagementId(e.target.value);
               }
            if (e.target.value.length === 0) {
                setEngagementId(e.target.value);
            }
        }
    };
    /**
      * Description
      * -----------
      * This method is to call when user writes project name
      * Parameters:
      * e: this one is onChange event parameter to get the input text
      */
    //Kingshuk's Code to maintain the same name for the project 
    const onProjectNameChange = (e) => {
        if (e.target.value.trim().length <= 50) {
            setProjectName(e.target.value);
            if (category === 1) {
                checkDuplicateProjectName(e.target.value, paceID);
            }    
            if (regex.test(e.target.value)) {
                setShowSpecialCharMessage(true);
            }
            else{
                setShowSpecialCharMessage(false);
            }            
            validateCreatePage();
        }
        if (e.target.value.trim().length > 50) {
            setShowMaxCharMessage(true);
        }
        else{
            setShowMaxCharMessage(false);
        }
    };
    //Code Ended

      /**
      * Description
      * -----------
      * This method is to call to check duplicate project name given by user
      * Parameters:
      * projectName: this one is project name that user selects
      * paceId: this one is paceid that user selects
      */
      const checkDuplicateProjectName = (projectName, paceId) => {
        const matchingRows = projectNameList.flat().filter(row =>
            row.projectName.toString().trim().toLowerCase() === projectName.toString().trim().toLowerCase()
            && row.paceId.toString().trim().toLowerCase() === paceId.toString().trim().toLowerCase());
        if (matchingRows.length > 0 ) {
            if(isCreate)
            {
                setShowProjectNameValid(true);
            }
            if(isEdit && projectName !== sessionStorage.getItem("DashboardProjectName"))
            {
                setShowProjectNameValid(true);
            }   
        }
        else {
            setShowProjectNameValid(false);
        }
    }

    /**
      * Description
      * -----------
      * This method is to call when user changes project to confidential or vice versa
      * Parameters:
      * e: this one is onChange event parameter to get the input action
      */
    const onConfidentialProjectChange = (data) => {
        setConfidentialProject(data);
        if (data !== null) {
            let selectedConfidentialProj = confidentialProjList.find(
                (confidentProj) => confidentProj.id === data
            );
            if (selectedConfidentialProj) {
                setConfidentialProject(data);
                if (data === 1) {
                    setActualConfidentialProject(projectMasterData.ConfidentialCheck);
                    setClientName(projectMasterData.ClientNameMask);
                }
                else {
                    let paceDetails = paceIdList?.find((s) => s.paceId === Number(paceID));
                    setActualClientName(paceDetails?.clientName);
                    setActualConfidentialProject(projectMasterData.ConfidentialUnCheck);
                    setClientName(actualClientName);
                }
            }
        }
        validateCreatePage();
    }

    useEffect(() => {
        setClientName(actualClientName);
    }, [actualClientName])
    /**
      * Description
      * -----------
      * This method is to call when user writes client name
      * Parameters:
      * e: this one is onChange event parameter to get the input text
      */
    const onClientNameChange = (e) => {
        if (e.target.value.length <= 255) {
            setClientName(e.target.value);
            setActualClientName(e.target.value)
        }
    };
    /**
      * Description
      * -----------
      * This method is to call when user selects any area
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onAreaChange = (data) => {
        if (isEdit) {
            setRegionList(
                projectMasterData.RegionConfig.filter(
                    (region) => region.areaId === data
                )
            );
        }
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any region
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onRegionChange = (regionId) => {
        if (isCreate) {
            if (regionId !== null) {
                let selectedRegion = regionList.find((region) => region.id === regionId);
                if (selectedRegion) {
                    setRegion(regionId);
                    let countries = projectMasterData.CountryConfig.filter(
                        (country) => country.regionId === regionId
                    ).sort(function (a, b) {
                        const textA = a.content.toUpperCase();
                        const textB = b.content.toUpperCase();
                        if (textA < textB) {
                            return -1;
                          } else if (textA > textB) {
                            return 1;
                          } else {
                            return 0;
                          }
                    });
                    const currentCountry = projectMasterData.CountryConfig.find(c => c.id === country);
                    const countryId = countries.find(c => c.content === currentCountry.content)?.id;
                    setCountryList(countries);
                    setCountry(countryId);
                }
            }
        }

        if (isEdit) {
            setCountryList(
                projectMasterData.CountryConfig.filter(
                    (country) => country.regionId === regionId
                ).sort(function (a, b) {
                    const textA = a.content.toUpperCase();
                    const textB = b.content.toUpperCase();
                    if (textA < textB) {
                        return -1;
                      } else if (textA > textB) {
                        return 1;
                      } else {
                        return 0;
                      }
                })
            );
        }
        validateCreatePage();
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any country
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onCountryChange = (e) => {
        setCountry(e);
        validateCreatePage();
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any deal
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onDealSizeChange = (data) => {
        setDealSize(data);
        if (data !== null) {
            let selectedDealSize = dealSizeList.find(
                (dealSize) => dealSize.id === data
            );

            if (selectedDealSize) {
                setDealSize(data);
            }
        }
        validateCreatePage();
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any targetsector
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onTargetSectorChange = (data) => {
        setTargetSubSector("");
        setTargetSubSubSector("");
        if (isCreate) {
            if (data !== null) {
                let selectedTarget = targetSectorList.find((target) => target.id === data);
                if (selectedTarget) {
                    setTargetSector(data);
                    setTargetSubSectorList(
                        projectMasterData.SubSectorConfig.filter(
                            (subsector) => subsector.sectorId === data
                        )
                    );
                }
            }
        }

        if (isEdit) {
            setTargetSector(data);
            setTargetSubSectorList(
                projectMasterData.SubSectorConfig.filter(
                    (subsector) => subsector.sectorId === data
                )
            );
        }
        validateCreatePage();
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any subsector
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onSubSectorChange = (data) => {
        setTargetSubSubSector("");
        if (isCreate) {
            if (data !== null) {
                let selectedsubSector = targetSubSectorList.find((target) => target.id === data);
                if (selectedsubSector) {

                    setTargetSubSector(data);
                    setTargetSubSubSectorList(
                        projectMasterData.SubSubSectorConfig.filter(
                            (subsector) => subsector.sectorId === data
                        )
                    );
                }
            }
        }

        if (isEdit) {
            setTargetSubSector(data);
            setTargetSubSubSectorList(
                projectMasterData.SubSubSectorConfig.filter(
                    (subsector) => subsector.sectorId === data
                )
            );
        }
        validateCreatePage();
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any subsubsector
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onSubSubSectorChange = (data) => {
        setTargetSubSubSector(data);
        validateCreatePage();
    }

    /**
      * Description
      * -----------
      * This method is to call when user selects any description
      * Parameters:
      * e: this one is onChange event parameter to get the selected text
      */
    const onDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

const validateEngagementID = () => {
    if(sharedData.invalidengagementID)
        {
            setFocusedElement(document.activeElement?.placeholder?.toString().trim().includes("Engagement"));
            if ((engagementId.length != 0) && (!focusedElement) && (engagementId.length<8))
                {
                    setErrorMessageEID(true);
                }
            else
            {
                setErrorMessageEID(false);
            }
        }
    else
        {
           setErrorMessageEID(false);
        }    
}

const onfocus = () => {
    setFocusedElement(document.activeElement.placeholder.toString().trim().includes("Engagement"));
}


    /**
      * Description
      * -----------
      * This method is to call when validation is needed wheather user enters all mandatory fields
      */
    const validateCreatePage = () => {

        validateEngagementID();
        let validate = true;
        if (category === "") {
            validate = false;
        }

        if (category === 1) {
            if (paceID === "") {
                validate = false;
            }

            if(isInvalidPaceID) validate = false;
        }
        if (category === 1) {
            if ((engagementId === "") || (engagementId.length > 8)){
                validate = false;
            }
        }
        if (category === 1) {
            if((engagementId!==null)&&(engagementId!==undefined)&&(engagementId.length>0))
            {
            if ((engagementId.length < 8)||(sumOfDigitsisZero(engagementId)===0)||(!isvalidEightDigitNumber(engagementId)))
            {
                setInvalidEngagementId(true);
                validate = false;
            }
            else{
                setInvalidEngagementId(false);
            }
           }            
        }

        if (projectName === "") {
            validate = false;
        }
        if (category === 1) {
            if (confidentialProject === "") {
                validate = false;
            }
        }

        if (showProjectNameValid === true) {
            validate = false;
        }

        if (showSpecialCharMessage === true) {
            validate = false;
        }

        if (showMaxCharMessage === true) {
            validate = false;
        }

        if (isEdit) {
            if (showProjectNameValid === true || showSpecialCharMessage === true || showMaxCharMessage === true) {
                sharedData.invalidengagementID = true;
            }
            else {
                sharedData.invalidengagementID = false;
            }
        }

        if (category === 1) {
            if (clientName === "") {
                validate = false;
            }
        }

        if (area === "") {
            validate = false;
        }


        if (region === "") {
            validate = false;
        }


        if (country === "") {
            validate = false;
        }

        if (dealSize === "") {
            validate = false;
        }

        if (targetSector === "") {
            validate = false;
        }


        if (targetSubSector === "") {
            validate = false;
        }


        if (targetSubSubSector === "") {
            validate = false;
        }

        if (dataHosting === "") {
            validate = false;
        }
        if (description === "") {
            validate = false;
        }

        if(!isEdit && category === 1 && sharedData.invalidengagementID)
        {
            validate = false;
        }
        
        if (validate) {
            FillProjectDetails();
            if(!isEdit){
            sessionStorage.setItem('DashboardProjectName', projectName);
            }
            disablecheck(false, createData);
        }
        else {
            disablecheck(true, createData);
        }
       // onEngagementIdChange(e);
    }

    /**
      * Description
      * -----------
      * This method is to call to get all the details that users has inserted to create or edit a project
      */
    const FillProjectDetails = () => {
        createData.category = category.toString();
        createData.paceId = paceID.toString();
        createData.engagementId = engagementId.toString();
        createData.confidential = actualConfidentialProject.toString();
        createData.projectName = projectName.toString().trim();
        createData.clientName = clientName.toString();
        createData.area = area.toString();
        createData.region = region.toString();
        createData.country = country.toString();
        createData.dealSize = dealSize.toString();
        createData.targetSector = targetSector.toString();
        createData.targetSubSector = targetSubSector.toString();
        createData.targetSubSubSector = targetSubSubSector.toString();
        createData.dataHostingLocation = dataHosting.toString();
        createData.description = description.toString();
        sharedData.invalidengagementID = (engagementId.toString().length<8)
        

        if (isCreate) {
            createData.projectuuid = sessionStorage.getItem('DashboardProjectId')
            
        }
        if (isEdit) {
            createData.projectuuid = projectid;
        }
        if (isCreate) {
            createData.editFlag = constantData.CreateAction
            if (createData.projectuuid != "") {
                createData.editFlag = constantData.EditAction
            }
        }
        if (isEdit) {
            createData.editFlag = constantData.EditAction
        }
        createData.projectScope = projectScope
             
    }

    /**
      * Description
      * -----------
      * This method is to check wherether the user is sat or notsat and set category type
      */
    const userTypeCheck = () => {
        if (userType === "UserSat") {
            setCategory(1);
        }
    }
    /**
      * Description
      * -----------
      * This method is to load all the project name existing in database
      */
    const loadProjectName = () => {
        if (allProjects) {
            const newArray = allProjects.map(item => ({
                projectName: item.projectName.toString().trim().toLowerCase(),
                paceId: item.paceId
            }));
            projectNameList.push(newArray);
        }
       
    }

    

      const CustomValidationEngagementID = () => {
        if((engagementId.toString().trim().length > 8) || (engagementId.toString().trim()==="00000000") ||(engagementId.toString().trim().startsWith("0")))
        {
            return true;
        }
        return false;
    }
    /**
      * Description
      * -----------
      * This method is to call when user selects any project scope
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
    const onProjectScopeChange = (data) => {
        setProjectScope(data);
        if (data !== null) {
            let selectedProjectScope = projectScopeList.find(
                (projectscope) => projectscope.id === data
            );

            if (selectedProjectScope) {
                setProjectScope(data);
            }
        }
    }

    return (
        <div className="parent-project-detail">
            <div className={wrapperName}>
                <div className="Title1">
                    <div className="TextAndSupportingText">
                        <div className="Text2" >
                            <div className="Text6" >
                                {constantData.CreateProjectHeading}
                            </div>
                        </div>
                        <div className="Text2" >
                            <div>&nbsp;&nbsp;All fields are mandatory.</div>
                        </div>
                        <div className="ProjectDetailDivider" ></div>
                    </div>
                </div>
                <div className="Frame2">
                    <div className="Select" >
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Project type</div>
                                <MotifFormField>
                                <MotifSelect
                                    label="selectcategory"
                                    className="Input"
                                    disabled={categoryDisable}
                                    ariaLabelledBy="select-label"
                                    onChange={onCategoryChange}
                                    placeholder="Select Category"
                                    value={category}
                                    required
                                >
                                    {categoryList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="StaticLabelInputField1">
                        <div className="DefaultInputFieldBase">
                            <div className="InputWithLabel">
                                <div className="Label">PACE ID</div>
                                <MotifTypeahead
                                    label="inputpaceid"
                                    className="motif-input PaceIdInput ProjectDetailDropdown"
                                    disabled={paceIDDisable}
                                    filterProp="paceId"
                                    data-testid="inputpaceid"
                                    id="users-typeahead"
                                    items={paceDropdownList}
                                    onBlur={onPaceIdLeave}
                                    onChange={onPaceIdChange}
                                    onSelect={onPaceIdSelect}
                                    open={paceIDListShow}
                                    value={paceID}
                                    placeholder="Enter PACE ID"
                                    required
                                /><div className="pace-id-error">{isInvalidPaceID &&(
                                    <MotifErrorMessage>
                                      Please select a valid PACE ID
                                    </MotifErrorMessage>          
                                  )} </div>  
                            </div>
                        </div>
                    </div>

                    <div className="StaticLabelInputField1" >
                        <div className="DefaultInputFieldBase">
                            <div className="InputWithLabel">
                                <div className="Label">Engagement ID
                                
                                </div>
                                
                                
                                <MotifFormField className="engagementId">

                                    <MotifInput
                                        label="inputengagementid"
                                        disabled={engagementIdDisable}
                                        onFocus={onfocus}
                                        onChange={onEngagementIdChange}
                                        onBlur={validateCreatePage}

                                        data-testid="engagementId"
                                        type='text'
                                        required
                                        value={engagementId}
                                        placeholder="Enter Engagement ID"
                                    />
                                    {ErrorMessageEID && (
                                        <MotifErrorMessage className="errMsg">
                                            Engagement ID must consist of 8 digits!
                                        </MotifErrorMessage>
                                    )}

                                    {CustomValidationEngagementID() && (
                                        <MotifErrorMessage className="errMsg">
                                            Engagement ID invalid
                                        </MotifErrorMessage>
                                    )}
                                    <div style={{ position: "absolute", top: "1rem", right: "2.5rem" }}>

                                        <MotifTooltip
                                            placement="bottom"
                                            id="emptyEngagementIdTooltip"
                                            hideCloseButton={true}
                                            hide={engagementIdDisable ? true : false}
                                            trigger={
                                                <img src={helpicon} alt="" />
                                            }
                                        >
                                            <center>Enter 11111111 if the <br />Engagement ID is pending</center>
                                        </MotifTooltip></div>

                                </MotifFormField>
                      <div className="explore-data-container-prompt-question-container-child">
                      </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="NewFrame">
                    <div className="StaticLabelInputField1" >
                        <div className="DefaultInputFieldBase" >
                            <div className="InputWithLabel" >
                                <div className="Label">Project name</div>
                                <MotifFormField className="error-box">
                                <MotifInput
                                    label="inputprojectname"
                                    disabled={projectNameDisable}
                                    onChange={onProjectNameChange}
                                    data-testid="inputprojectname"
                                    onBlur={validateCreatePage}
                                    className='PaceIdInput'
                                    value={projectName}
                                    placeholder="Enter Project name"
                                />
                                {showProjectNameValid
                                    ?
                                    <p id="validation" className="Text10">Project name already in use, choose another name</p> : null
                                }
                                {showSpecialCharMessage &&
                                    <MotifErrorMessage className="Text10">
                                            Special characters not allowed
                                    </MotifErrorMessage>
                                }
                                {showMaxCharMessage &&
                                    <MotifErrorMessage className="Text10">
                                            Maximum limit reached - 50 characters
                                    </MotifErrorMessage>
                                }
                                 </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Confidential project</div>
                                <MotifFormField>
                                    <MotifSelect
                                        placeholder="Select"
                                        label="selectconfidentialproject"
                                        disabled={confidentialProjectDisable}
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onChange={onConfidentialProjectChange}
                                        onBlur={validateCreatePage}
                                        value={confidentialProject}
                                        required                                >

                                    {confidentialProjList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select" >
                        <div className="InputDropdownBase" >
                            <div className="InputWithLabel" >
                                <div className="Frame5383" >
                                    <div className="Label">Client name</div>
                                    <div className="Frame5357">
                                    </div>
                                </div>
                                <MotifInput
                                    placeholder="Enter Client name"
                                    label="inputclientname"
                                    disabled={true}
                                    onChange={onClientNameChange}
                                    onBlur={validateCreatePage}
                                    className='InputCLient'
                                    value={clientName}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="NewFrame">
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Area</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="selectarea"
                                        disabled={areaDisable}
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onBlur={validateCreatePage}
                                        placeholder="Select area"
                                        value={area}
                                        required
                                    >

                                    {areaList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>

                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Region</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="selectregion"
                                        disabled={regionDisable}
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onChange={onRegionChange}
                                        onBlur={validateCreatePage}
                                        placeholder="Select region"
                                        value={region}
                                        required
                                    >

                                    {regionList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Country</div>
                                <MotifFormField>
                                <MotifSelect
                                    label="selectcoutry"
                                    disabled={countryDisable}
                                    className="Input"
                                    ariaLabelledBy="select-label"
                                    onChange={onCountryChange}
                                    onBlur={validateCreatePage}
                                    placeholder="Select country"
                                    value={country}
                                    required
                                >
                                    {countryList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="NewFrame">
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Deal size (USD)</div>
                                <MotifFormField>
                                <MotifSelect
                                    label="selectdealsize"
                                    className="Input"
                                    ariaLabelledBy="select-label"
                                    onChange={onDealSizeChange}
                                    onBlur={validateCreatePage}
                                    placeholder="Select deal size"
                                    value={dealSize}
                                    required
                                >
                                    {dealSizeList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Target sector</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="selecttargetsector"
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        data-testid="selecttargetsector"
                                        onChange={onTargetSectorChange}
                                        onBlur={validateCreatePage}
                                        placeholder="Select sector"
                                        value={targetSector}
                                        required
                                    >

                                    {targetSectorList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Target sub-sector</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="selecttargetsubsector"
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onChange={onSubSectorChange}
                                        onBlur={validateCreatePage}
                                        placeholder="Select sub-sector"
                                        value={targetSubSector}
                                        required
                                    >

                                    {targetSubSectorList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="NewFrame">
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Target sub-sub-sector</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="targetsubsubsector"
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onChange={onSubSubSectorChange}
                                        onBlur={validateCreatePage}
                                        placeholder="Select sub-sub-sector"
                                        value={targetSubSubSector}
                                        required
                                    >

                                    {targetSubSubSectorList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Data hosting</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="selectdatahosting"
                                        disabled={dataHostingDisable}
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onBlur={validateCreatePage}
                                        placeholder="Select"
                                        value={dataHosting}
                                        required                                >

                                    {dataHostingList.map(item => {
                                        return (
                                            <MotifOption className={item.content} value={item.id} key={item.id}>
                                                {item.content}
                                            </MotifOption>
                                        );
                                    })}
                                </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                    <div className="Select">
                        <div className="InputDropdownBase">
                            <div className="InputWithLabel">
                                <div className="Label">Project Scope (Optional)</div>
                                <MotifFormField>
                                    <MotifSelect
                                        label="selectprojectscope"
                                        className="Input"
                                        ariaLabelledBy="select-label"
                                        onChange={onProjectScopeChange}
                                        placeholder="Select"
                                        multiple={true}
                                        value={projectScope}>
                                        {
                                            [...projectScopeList.map(item => {
                                                return (
                                                    <MotifOption className={item.scopeName} value={item.scopeId} key={item.scopeId}>
                                                        {item.scopeName}
                                                    </MotifOption>
                                                );
                                            }),
                                                <MotifOption className="coming-soon" key="coming-soon">
                                                    More coming soon...
                                                </MotifOption>
                                            ]
                                        }

                                    </MotifSelect>

                                </MotifFormField>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="NewFrame">
                    <div className="TextArea">
                        <div className="Textarea">
                            <div className="LabelGroup" >
                                <div className="LabelOutside">Description</div>
                            </div>
                            <MotifTextArea
                                label="inputdescription"
                                onChange={onDescriptionChange}
                                onBlur={validateCreatePage}
                                data-testid="InputDescription"
                                className="InputDescription"
                                value={description}
                                placeholder="Enter a description"
                            />
                        </div>
                        <span className="projectdetail-bottom-desc">
                            EY does not intentionally collect any sensitive personal data from you via the Tool. 
                            The Tools intention is not to process such information. 
                            Accordingly, you should not enter any unnecessary personal information or any sensitive personal data 
                            (including government identifiers such as tax file numbers or social security numbers/national identification numbers), 
                            client confidential information (except client entity names), audit secrets, state secrets, trade secrets, 
                            or anything that would violate professional secrecy or confidentiality rules or that would be considered 
                            abusive/irrelevant in the Tool.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}