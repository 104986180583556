import React, { useEffect, useState } from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import noProject from "../../../../assets/image/no-project-image.png";
import PlatformService from "../../../../services/platformService";
import InstantInsightPrompt from "./InstantInsightPrompt";
import trashDisable from "../../../../assets/image/trashDisable.svg";
import trashPrompt from "../../../../assets/image/trashPrompt.svg";
import arrowRight from "../../../../assets/image/arrow-right.svg";
import "./InstantInsightPrompts.css";
import { MotifButton, MotifToast } from "@ey-xd/motif-react";
import DeletePrompt from "./DeletePromptPopup";

const InstantInsightPromptDetail = (props) => {
  const platformService = new PlatformService();
  const [node, setNode] = useState({});
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const [promptsSelected, setPromptsSelected] = useState([]);
  const [activePromptList, setActivePromptList] = useState([]);
  const [testPromptList, setTestPromptList] = useState([]);
  const tabIds = { activePrompt: "activePrompt", testPrompt: "testPrompt" };

  useEffect(() => {
    setNode(props.selectedNode);
  }, [props.selectedNode]);

  useEffect(() => {
    if (node?.id && node?.nodeType === 3) {
      getActivePrompts(node.id);
      getTestPrompts(node.id);
    } else {
      setActivePromptList([]);
      setTestPromptList([]);
    }
  }, [node]);

  // Enable disable Buttons
  useEffect(() => {
    const anyPromptsSelected = activePromptList.filter(
      (data) => data.isChecked === true
    );
    if (anyPromptsSelected.length > 0) {
      setDisableDelete(true);
    } else {
      setDisableDelete(false);
    }
    setPromptsSelected(anyPromptsSelected);
  }, [activePromptList]);

  // Delete Confirm and reload prompts
  useEffect(() => {
    if (showDeletedComplete) {
      if (node?.id && node?.nodeType === 3) {
        getActivePrompts(node.id);
        getTestPrompts(node.id);
      } else {
        setActivePromptList([]);
        setTestPromptList([]);
      }

      setshowDeletedComplete(false);
    }
  }, [showDeletedComplete]);

  // To delete a member from UI /DB. Input is row
  const deletePrompt = async () => {
    setShowDeleteConfirmPopup(true);
  };
  // This function is for closing the toaster message after delete prompts
  const onclosepromptclick = () => {
    setshowDeletedComplete(false);
  };

  const getActivePrompts = async (subSectionId) => {
    const prompts = await platformService.getInstantInsightActivePrompts(
      subSectionId
    );
    if (prompts) {
      let promptList = [];
      prompts.map((p) => {
        promptList.push({ ...p, isChecked: false });
      });
      setActivePromptList(promptList);
    }
  };

  const getTestPrompts = async (subSectionId) => {
    const prompts = await platformService.getInstantInsightTestPrompts(
      subSectionId
    );
    if (prompts) {
      let promptList = [];
      prompts.map((p) => {
        promptList.push({ ...p, isChecked: false });
      });
      setTestPromptList(promptList);
    }
  };

  const onSelectedActivePrompt = (id, checked) => {
    let activePrompts = activePromptList.map((prompt) =>
      prompt.platformPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setActivePromptList(activePrompts);
  };

  const onSelectedTestPrompt = (id, checked) => {
    let testPrompts = testPromptList.map((prompt) =>
      prompt.platformPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setTestPromptList(testPrompts);
  };

  const handleTabClick = (tab) => {
    switch (tab) {
      case tabIds.activePrompt:
        document.getElementById("promptIIActivePromptDetail").style.display =
          "block";
        document.getElementById("promptIITestPromptDetail").style.display =
          "none";
        break;
      case tabIds.testPrompt:
        document.getElementById("promptIIActivePromptDetail").style.display =
          "none";
        document.getElementById("promptIITestPromptDetail").style.display =
          "block";
        break;
      default:
    }
  };

  return (
    <div className="ii-prompt-response-detail">
      {(() => {
        if (activePromptList.length > 0) {
          return (
            <div className="ii-detail-container">
              <div className="ii-detail-subsection-header">{node?.value}</div>
              <MotifTabNavigation id="tabPromptResponse" defaultActiveKey={0}>
                <MotifTabControl
                  onClick={() => handleTabClick(tabIds.activePrompt)}
                >
                  Active Prompts
                </MotifTabControl>
                <MotifTabControl
                  onClick={() => handleTabClick(tabIds.testPrompt)}
                >
                  Test Prompts
                </MotifTabControl>
              </MotifTabNavigation>
              <div id="promptIIActivePromptDetail">
                <div className="ii-prompt-response-container">
                  {activePromptList.length > 0 && (
                    <div className="ii-prompt-action-section">
                      <MotifButton
                        label="btnCreate"
                        className="li-delete-button"
                        onClick={deletePrompt}
                        disabled={!disableDelete}
                      >
                         {disableDelete && <img src={trashPrompt} alt=""></img>}
                         {!disableDelete && <img src={trashDisable} alt=""></img>}
                        <span className="txtdeleteprompt">Delete Prompt</span>
                      </MotifButton>
                      <MotifButton
                        label="btnCreate"
                        className="li-move-prompt"
                        disabled={!disableDelete}
                        // onClick={navigateCreateProject}
                      >
                        <img src={arrowRight} alt=""></img>
                        Move Prompt
                      </MotifButton>
                    </div>
                  )}
                  {activePromptList.length > 0 &&
                    activePromptList?.map((activePrompt, index) => {
                      return (
                        <InstantInsightPrompt
                          key={index.toString()}
                          prompt={activePrompt}
                          onCheckPrompt={onSelectedActivePrompt}
                        />
                      );
                    })}
                </div>
              </div>

              <div id="promptIITestPromptDetail">
                <div className="ii-prompt-response-container">
                  {testPromptList.length > 0 &&
                    testPromptList?.map((testPrompt, index) => {
                      return (
                        <InstantInsightPrompt
                          key={index.toString()}
                          prompt={testPrompt}
                          onCheckPrompt={onSelectedTestPrompt}
                        />
                      );
                    })}
                </div>
              </div>

              {showDeleteConfirmPopup && (
                <DeletePrompt
                  setshowDeletedComplete={setshowDeletedComplete}
                  setShowDeleteConfirmPopup={setShowDeleteConfirmPopup}
                  promptsSelected={promptsSelected}
                />
              )}
              {showDeletedComplete && (
                <MotifToast
                  variant="info"
                  actionName=""
                  position="bottom"
                  className="instant-toast-msg"
                  onClose={() => {
                    onclosepromptclick();
                  }}
                >
                  <div>Prompt deleted successfully</div>
                </MotifToast>
              )}
            </div>
          );
        } else {
          return (
            <div className="ii-prompt-detail-no-selection">
              <img src={noProject} alt="no selection made" />
              <div className="ii-prompt-no-selection-div">
                <span className="ii-prompt-no-selection-text">
                  No selection made
                </span>
                <span className="ii-prompt-no-selection-desc">
                  Please make a selection from the left pane
                </span>
              </div>
            </div>
          );
        }
      })()}
    </div>
  );
};
export default InstantInsightPromptDetail;
