import React from "react";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";

const InstantInsightPrompt = (props) => {
  const onClickAccordion = (event) => {
    if (event.target.className === "motif-checkbox-custom") {
      props.onCheckPrompt(props?.prompt?.platformPromptId, true);
    } else if (
      event.target.parentNode.className === "motif-checkbox-checked-icon"
    ) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, false);
    }
  };

  return (
    <MotifAccordion
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => {}}
      onOpen={() => {}}
      onClick={($event) => onClickAccordion($event)}
    >
      <MotifAccordionTrigger>
        <div className="ii-response-accordion-trigger">
          <MotifCheckbox
            name="chkPrompt"
            checked={props?.prompt?.isChecked}
            value={props?.prompt?.isChecked}
          ></MotifCheckbox>
          {props?.prompt?.platformPromptTitle}
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        <div className="ii-prompt-detail-text">
          {props?.prompt?.platformPromptText}
        </div>
      </MotifAccordionContent>
    </MotifAccordion>
  );
};
export default InstantInsightPrompt;
