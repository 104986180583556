
import React from 'react';
import { DashboardContext } from "../../context/dashboardContext";
import InstantInsights from './InstantInsights/InstantInsights';
import avatarImage from "../../assets/image/default-user-icon.jpg";
import exploreImage from "../../assets/image/explore.svg";
import logo from "../../assets/image/logo.svg";
import documentImage from "../../assets/image/document.svg";
import memberImage from "../../assets/image/member.svg";
import projectdetail from "../../assets/image/projectdetail.svg";
import workspace from "../../assets/image/workspace.svg";
import magicwand from "../../assets/image/magic-wand.svg";
import menu from "../../assets/image/menu.svg";
import logOut from "../../assets/image/log-out.svg";
import projectWorkspace from "../../assets/image/project-workspace.svg";
import { useMsal } from '@azure/msal-react';

import {
    MotifVerticalNavigationMenuItem, MotifVerticalNavigationMenu, MotifVerticalNavigation,
    MotifVerticalNavigationContent, MotifVerticalNavigationFooter
} from "@ey-xd/motif-react";
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import CommonComponent from '../common/CommonComponent';

/**
   * Description
   * -----------
   * This method will be helpful for using the react hooks in class component
   */
function withMyHook(Component) {
    return function WrappedComponent(props) {
        const myInstance = useMsal();
        const myLocation = useLocation();
        return <Component {...props} myInstance={myInstance} myLocation={myLocation}/>;
    }
}
class ProjectDashboard extends React.Component {

    constructor() {
        super();

        this.state = {
            collapseMenu: true,
            showUserMenu: false,
            exploreClick: true,
            docLibClick: false,
            membersClick: false,
            prjDetClick: false,
            promptLibClick: false,
            navigateToDashboard: false,
            insightsClick: false,
            showInsightsPage: false,
            showOtherPages: true
        }

        this.highlightExploreDataNavigationOption = this.highlightExploreDataNavigationOption.bind(this);
        this.highlightProjectDataNavigationOption = this.highlightProjectDataNavigationOption.bind(this);
        this.projectMasterData = require("../../data/projectMasterData.js");
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const url = window.location.pathname;

        // Related to conditional rendering of Instant Insights page and others
        if (url === '/ProjectDashboard/InstantInsights') {
            this.setState({ showInsightsPage: true, showOtherPages: false });
        }
        else {
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }

        // Set highlight as per url
        this.setMenuHighlightAsPerURL(url);
    }
    onToggleButtonClick = () => {
        if (this.state.collapseMenu === true) {
            this.setState({ collapseMenu: false });
        }
        else {
            this.setState({ collapseMenu: true });
        }
    };
    showLoggedInUserMenu = () => {
        this.setState({ showUserMenu: true });
        document.body.addEventListener('click', this.hideLoggedInUserMenu);
    };
    hideLoggedInUserMenu = () => {
        this.setState({ showUserMenu: false });
    };
    OnLogoClick = () => {
        this.setState({ navigateToDashboard: true });
    };

    /**
   * Description
   * -----------
   * This method logout from Azure Ad and navigate to logout page
   */
    handleLogOut = (e) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        const myInstance = this.props.myInstance;

        const logoutRequest = {
            account: myInstance.instance.getActiveAccount(),
            postLogoutRedirectUri: "/LogOut",
        };
        myInstance.instance.logout(logoutRequest);
    };

    //Set Explore Your Data menu option as selected
    highlightExploreDataNavigationOption() {
        if (this) {
            this.setState({ exploreClick: true });
            this.setState({ docLibClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: false });
            this.setState({ projectWorkspaceClick: false });
            this.setState({ promptLibClick: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

    //Set ProjectDetail menu option as selected
    highlightProjectDataNavigationOption() {
        if (this) {
            this.setState({ exploreClick: false });
            this.setState({ docLibClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: true });
            this.setState({ projectWorkspaceClick: false });
            this.setState({ promptLibClick: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

    //Set DcoumentLibrary menu option as selected
    highlightDocLibraryNavigationOption = () => {
        if (this) {
            this.setState({ docLibClick: true });
            this.setState({ exploreClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: false });
            this.setState({ projectWorkspaceClick: false });
            this.setState({ promptLibClick: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

     //Set DcoumentLibrary menu option as selected
     highlightPersonalWorkspaceNavigationOption = () => {
        if (this) {
            this.setState({ projectWorkspaceClick: true });
            this.setState({ exploreClick: false });
            this.setState({ membersClick: false });
            this.setState({ prjDetClick: false });
            this.setState({ docLibClick: false });
            this.setState({ promptLibClick: false });
            this.setState({ insightsClick: false });
            this.setState({ showInsightsPage: false, showOtherPages: true });
        }
    };

    /**
     * Set menu option highlight as per url.
     * @param {string} url 
     */
    setMenuHighlightAsPerURL = (url) => {
        switch (url) {
            case "/ProjectDashboard/ExploreData":
                this.highlightExploreDataNavigationOption();
                break;
            case "/ProjectDashboard/DocumentLibrary":
                this.highlightDocLibraryNavigationOption();
                break;
            case "/ProjectDashboard/Members":
                this.highlightMembersNavigationOption();
                break;
            case "/ProjectDashboard/Projectdetail":
                this.highlightProjectDataNavigationOption();
                break;
            case "/ProjectDashboard/PromptLibraries/Personal":
                this.highlightPromptLibraryNavOption();
                break;
            case "/ProjectDashboard/ProjectWorkspace/Personal":
                this.highlightPersonalWorkspaceNavigationOption();
                break;
            case "/ProjectDashboard/InstantInsights":
                this.highlightInsightsMenuOption();
                break;
        }
    }

    /**
     * Set Members menu option as selected.
     */
    highlightMembersNavigationOption = () => {
        this.setState({ membersClick: true });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ prjDetClick: false });
        this.setState({ promptLibClick: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ insightsClick: false });
        this.setState({ showInsightsPage: false, showOtherPages: true });
    }

    /**
     * Set Prompt Library menu item as selected.
     */
    highlightPromptLibraryNavOption = () => {
        this.setState({ promptLibClick: true });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ membersClick: false });
        this.setState({ prjDetClick: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ insightsClick: false });
        this.setState({ showInsightsPage: false, showOtherPages: true });
    }

    /**
     * Set Insights menu item as selected.
     */
    highlightInsightsMenuOption = () => {
        this.setState({ showInsightsPage: true, showOtherPages: false });
        this.setState({ promptLibClick: false });
        this.setState({ exploreClick: false });
        this.setState({ docLibClick: false });
        this.setState({ membersClick: false });
        this.setState({ projectWorkspaceClick: false });
        this.setState({ prjDetClick: false });
        this.setState({ insightsClick: true });
    }

    //This is to check DocumentLibrary state variable
    componentDidUpdate() {
        if (this.props.myLocation.state?.showDocLibrary) {
            this.highlightDocLibraryNavigationOption();
            this.props.myLocation.state.showDocLibrary = false;
        }
    }

    getCountry = (countryId) => {
        if (!countryId) {
            countryId = sessionStorage.getItem("ProjectCountry");
        }
        const country = this.projectMasterData.CountryConfig.find(country => country.id === countryId);
        return country ? country.content : "NA";
    }


    render() {

        return (
            <div>
                <div className="ProjectDashboardEditDetails">
                    <div className='projectdashboardnavdiv'
                    >
                        <MotifVerticalNavigation collapse={this.state.collapseMenu}>
                            <MotifVerticalNavigationContent>
                                <MotifVerticalNavigationMenu role="menu">

                                    <Link to="/ProjectDashboard/ExploreData" role="menuitem" onClick={() => {
                                        this.highlightExploreDataNavigationOption();
                                    }} >
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.exploreClick ? "active-menu" : ""}
                                                icon={<img alt='icon' src={exploreImage} />}
                                                label="Explore Your data"
                                                type="submit"
                                            >
                                                Explore your data
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>

                                    <Link to="/ProjectDashboard/DocumentLibrary" role="menuitem" onClick={() => {
                                        this.highlightDocLibraryNavigationOption();
                                    }}>
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.docLibClick ? "active-menu" : ""}
                                                icon={<img alt='icon' src={documentImage} />}
                                                label="Document Library"
                                            >
                                                Document library
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                    <Link to="/ProjectDashboard/Members" role="menuitem" onClick={this.highlightMembersNavigationOption}>
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.membersClick ? "active-menu" : ""}
                                                icon={<img alt='icon' src={memberImage} />}
                                                label="Members"
                                            >
                                                Members
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                    <Link to="/ProjectDashboard/Projectdetail" role="menuitem" onClick={() => {
                                        this.highlightProjectDataNavigationOption();
                                    }}>
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.prjDetClick ? "active-menu" : ""}
                                                icon={<img alt='icon' src={projectdetail} />}
                                                label="Project Details"
                                            >
                                                Project details
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                </MotifVerticalNavigationMenu>
                                <MotifVerticalNavigationFooter >
                                    <div role="menu">
                                        {/* <Link to="/ProjectDashboard/ProjectWorkspace/Personal" role="menuitem" onClick={() => {
                                                 this.highlightPersonalWorkspaceNavigationOption();
                                                 }}>
                                                <div role="menu">
                                                    <MotifVerticalNavigationMenuItem className={this.state.projectWorkspaceClick ? "active-menu" : ""}
                                                        icon={<img alt='icon' src={projectWorkspace} />}
                                                        label="Project Workspace"
                                                    >
                                                        Project Workspace
                                                    </MotifVerticalNavigationMenuItem>
                                                </div>
                                            </Link> */}
                                        <Link to="/ProjectDashboard/PromptLibraries/Personal" role="menuitem" onClick={this.highlightPromptLibraryNavOption}>
                                            <div role="menu">
                                                <MotifVerticalNavigationMenuItem className={this.state.promptLibClick ? "active-menu" : ""}
                                                    icon={<img alt='icon' src={workspace} />}
                                                    label="Prompt Libraries"
                                                >
                                                    Prompt Libraries
                                                </MotifVerticalNavigationMenuItem>
                                            </div>
                                        </Link>
                                        <Link to="/ProjectDashboard/InstantInsights" role="menuitem" onClick={this.highlightInsightsMenuOption}>
                                            <div role="menu">
                                                <MotifVerticalNavigationMenuItem className={this.state.insightsClick ? "active-menu" : ""}
                                                    icon={<img alt='icon' src={magicwand} />}
                                                    label="Instant Insights"
                                                >
                                                    Instant Insights
                                                </MotifVerticalNavigationMenuItem>
                                            </div>
                                        </Link>
                                    </div>
                                </MotifVerticalNavigationFooter>
                            </MotifVerticalNavigationContent>

                        </MotifVerticalNavigation>
                        <div className='utilmargin'>
                            {
                                this.state.showOtherPages &&
                                <Outlet context={{ highlightExploreDataNavigationOption: this.highlightExploreDataNavigationOption, highlightProjectDataNavigationOption: this.highlightProjectDataNavigationOption }} />
                            }
                            <div style={{ display: this.state.showInsightsPage ? 'block' : 'none', height: '100%' }}>
                                <InstantInsights highlightProjectDataNavigationOption={this.highlightProjectDataNavigationOption} showInsightsPage={this.state.showInsightsPage}></InstantInsights>
                            </div>
                        </div>
                    </div>
                    <div className="ProjectDashboardHeaderNav">
                        <div className="ProjectDashboardLogoAndMenu" >
                            <div className="ProjectDashboardLogoButton" >
                                <div className="ProjectDashboardLogoButtonBase" >
                                    <img alt='icon' onClick={() => this.onToggleButtonClick()}
                                        src={menu}
                                    ></img>
                                </div>
                            </div>

                            <div className="ProjectDashboardLogoEngGap" >
                                <div className="ProjectDashboardLogoProduct" >
                                    <div className="ProjectDashboardLogo" >
                                        <div className="ProjectDashboardContainerLogo" >
                                            <img alt='icon' className="img-hover" src={logo} onClick={() => this.OnLogoClick()}></img>
                                        </div>
                                    </div>
                                    <div className="ProductName">Digital Diligence Assistant</div>
                                </div>
                            </div>
                        </div>
                        <div className="ProjectDashboardContent1" >
                            <div className="engagementLocationSelectDiv">
                                <DashboardContext.Consumer>
                                    {context => (
                                        <div className="engagementLocationSelectLabel">Engagement location - {this.getCountry(context.projectCountry)}</div>
                                    )}
                                </DashboardContext.Consumer>
                            </div>
                            <div className="ProjectDashboardSearchGroup">
                                <div className="ProjectDashboardIconGroup" >
                                    <div className="ProjectDashboardButtonsGroup" >
                                    </div>
                                </div>
                            </div>
                            <div className="ProjectDashboardNavDropdown" >
                                <div className="ProjectDashboardDropdown" >
                                    {this.state.showUserMenu && (<div className='ProjectDashboardDropdownDiv'>
                                        <div className="userMenupopupcontainer">
                                            <div className='userMenupopupcontainerDiv'>
                                                <div className="usermenuwidthscratch">
                                                    <div className="usermenuwidth">
                                                        <div className='usermenuwidthDiv'>
                                                            <div className="usermenuchane">
                                                                <div className="usermenushow">
                                                                    <div className="displaymenu">

                                                                        <div
                                                                            className='displaymenuDiv'
                                                                        >
                                                                            {
                                                                                <img
                                                                                    src={avatarImage}
                                                                                    alt="Avatar"
                                                                                    className='AvatarDiv'
                                                                                ></img>
                                                                            }
                                                                            {
                                                                                <div className='CurrentUserNameDiv'>
                                                                                    {" "}
                                                                                    <b>{sessionStorage.getItem(
                                                                                        "CurrentUserName"
                                                                                    )}</b>
                                                                                    <div className='EmailDiv'>
                                                                                        {" "}
                                                                                        {sessionStorage.getItem("Email")}
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="Divider1 divider" />

                                                                        <div>
                                                                            <img className='logoutimg' alt='icon' src={logOut} />
                                                                            <a href="#"
                                                                                className="logout"
                                                                                target="_blank"
                                                                                rel="noopeer noreferrer"
                                                                                onClick={this.handleLogOut}
                                                                            > Log out
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                    <div className="Avatar" onMouseOver={this.showLoggedInUserMenu} onMouseDown={this.hideLoggedInUserMenu}>
                                        <img alt='icon' className="AvatarImage" src={avatarImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.navigateToDashboard &&
                    <Navigate to="../Dashboard"></Navigate>}
                    <CommonComponent/>
            </div>
        )
    }

}


export default withMyHook(ProjectDashboard);