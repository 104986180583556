import axios from "./axiosWithMsal";
import CommonService from "./commonService.js";

class PlatformService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }

  /**
   * This method returns structured data for insights tree
   * @returns Object
   */
  getScopeTreeData = async (scopeId) => {
    try {
      return await axios
        .get(this.apiUrl + "Platform/GetScopeTreeDetails?ScopeId=" + scopeId, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "GetScopeTreeData"
      );
    }
  };

  /**
   * This method returns instant insight active prompts list
   * @returns Object
   */
  getInstantInsightActivePrompts = async (subSectionId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetPlatformPromptDetails?SubSectionId=" +
            subSectionId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getInstantInsightPrompts"
      );
    }
  };

  /**
   * This method returns instant insight test prompts list
   * @returns Object
   */
  getInstantInsightTestPrompts = async (subSectionId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetPlatformPromptDetails?SubSectionId=" +
            subSectionId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getInstantInsightPrompts"
      );
    }
  };
 /**
   * This method delete instant insight prompts list
   * @returns sucess
   */
  DeletePrompts = async (platformPromptRequest) => {
    await axios
      .post(this.apiUrl + "Platform/DeletePlatformPrompts", platformPromptRequest, {
        headers : {
          "Content-type": "application/json; charset=UTF-8",
        }
      })
      .catch((error) => {
        this.commonService.logException(
            error,
            "Platform Component",
            "promptDelete"
          );    
      });
  };
}
export default PlatformService;
