/**
 * Expand prompt modal
 * Created on 26-08-24
 */
import React, { useEffect, useState, useCallback, useRef } from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import { MotifIconButton } from "@ey-xd/motif-react/Button";

import collapseprompt from "../../../assets/image/collapse.svg";

import { MotifRichTextEditor, MotifTooltip } from "@ey-xd/motif-react";
import "./ExploreData.css";

const ExpandPromptModal = (props) => {
  const [questionBar, setQuestionBar] = useState(props.questionBar);
  const promprRef = useRef(null);

  const handlePromptChange = (delta, old, source) => {
    setQuestionBar(delta);
  };

  const motifReactEditorModules = {
    toolbar: [
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const collapseClick = () => {
    props.setExpandPromptModal(!props.expandPromptModal);
    props.setQuestionBar(questionBar);
  };

  useEffect(() => {
    const checkModalLoaded = () => {
      const promptEditor = promprRef.current;
      if (promptEditor) {
        const numberedBtn = promptEditor.querySelector(
          `button[value="ordered"]`
        );
        const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
        if(numberedBtn){
          addCustomTooltip(numberedBtn, 'Numbered list');
        }
        if(bulletBtn){
          addCustomTooltip(bulletBtn, 'Bulleted list');
        }
      } else {
        // Retry after a short delay if the modal is not yet loaded
        setTimeout(checkModalLoaded, 10);
      }
    };
    checkModalLoaded();
  }, []);

  const addCustomTooltip = (button, tooltTipText) => {
    const tooltip = document.createElement('div');
    tooltip.className = 'tooltip-bulleted-numbered';
    tooltip.textContent = tooltTipText;
    document.body.appendChild(tooltip);

    button.addEventListener('mouseenter', (e) => {
      const rect = button.getBoundingClientRect();
      tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2}px`; // Center horizontally
      tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 5}px`; // Position above the button
  
      tooltip.style.opacity = 1;
    });

    button.addEventListener('mouseleave', () => {
      tooltip.style.opacity = 0;
    });

  }

  return (
    <MotifModal
      id="expandpromptModal"
      className="expand-modal-container"
      size="xl"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={collapseClick}
    >
      <MotifModalHeader
        className="expand-modal-header"
        closeButtonProps={{
          "aria-label": "Custom Close Button aria-label",
          title: "Custom Close Button title",
        }}
        closeModalButton={
         
              <MotifIconButton
                className="motif-icon-button motif-modal--header-icon-button motif-icon-button-small"
                onClick={() => collapseClick()}
              >
                 <MotifTooltip
            placement="left"
            trigger={
                <img src={collapseprompt} alt="collapse" />
            }
          >
            Collapse
          </MotifTooltip>
          </MotifIconButton>
        }
      >
        Ask a question
      </MotifModalHeader>
      <MotifModalBody className="expand-modal-body">
        <div id="modalExpandPrompt" ref={promprRef}>
          <MotifRichTextEditor
            id="expand-prompt-editor"
            className="expand-text-editor"
            placeholder="Ask a question..."
            value={questionBar}
            modules={motifReactEditorModules}
            onChange={handlePromptChange}
          />
        </div>
      </MotifModalBody>
    </MotifModal>
  );
};

export default ExpandPromptModal;
