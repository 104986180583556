import React, { useContext, useEffect, useState } from "react";
import {
  MotifFormField,
  MotifLabel,
  MotifOption,
  MotifSelect,
} from "@ey-xd/motif-react";
import "./InstantInsightPrompts.css";
import { DashboardContext } from "../../../../context/dashboardContext";
import ProjectScopeTree from "../../../ProjectDashboard/InstantInsights/ProjectScopeTree";
import PlatformService from "../../../../services/platformService";

const PromptScopeTree = (props) => {
  const platformService = new PlatformService();
  let { scopeAll } = useContext(DashboardContext);
  const [treeData, setTreeData] = useState(null);
  const [selectedTreeNode, setSelectedTreeNode] = useState(null);

  const handleTreeNodeClick = (node)=>{
    props.setSelectedNode(node);
  }

  /**
   * On Scope selected change to fetch the Tree structure data
   */
  const onScopeChange = async (item) => {
    let treeData = await platformService.getScopeTreeData(item.scopeId);
    setTreeData(treeData);
  };

  return (
    <div className="ii-prompt-left-section">
      <div
        id="ii-prompt-project-scope"
        className="ii-prompt-project-scope-container"
      >
        <span className="ii-prompt-select-scope-label">Prompt Scope Tree</span>
        <MotifFormField>
          {scopeAll?.length === 0 ? (
            <MotifLabel id="selectscope">No Project Scope Found</MotifLabel>
          ) : null}

          <MotifSelect
            id="scopeSelect"
            label="selectscope"
            data-testid="scopeSelect"
            className="InputInstantInsight"
            ariaLabelledBy="select-label"
            placeholder="Select Project Scope"
            onChange={onScopeChange}
            disabled={scopeAll?.length === 0}
            required
          >
            {scopeAll && [
              ...scopeAll.map((item) => {
                return (
                  <MotifOption
                    key={item.scopeName}
                    className={item.scopeName}
                    value={item}
                  >
                    {item.scopeName}
                  </MotifOption>
                );
              }),

              <MotifOption
                disabled={true}
                className="coming-soon"
                key="coming-soon"
              >
                More coming soon...
              </MotifOption>,
            ]}
          </MotifSelect>
        </MotifFormField>
      </div>
      <div className="ii-tree-container">
        {treeData?.value?.length > 0 && (
          <ProjectScopeTree
            treeValue={treeData.value}
            showLines={true}
            treeData={treeData}
            treeNodeClick={handleTreeNodeClick}
            showCheckbox={false}
            showIcons={false}
            isFirstTimeRefreshInstantInsight={false}
            setShowAcknowledgeRefreshPopup={null}
            setSelectedTreeNode={setSelectedTreeNode}
            setLaunchedBy={null}
          ></ProjectScopeTree>
        )}
      </div>
      <div className="ii-left-menu-footer">Edit Structure</div>
    </div>
  );
};
export default PromptScopeTree;
